tr.item-editor {
  .confirmable-action.delete-btn {
    display: none;
    vertical-align: middle;
  }
  span.drag-handle {
    transform: rotate(90deg);
    position: absolute;
    left: 10px;
    top: 18px;
    font-size: 18px;
    color: #393939;
    text-shadow: $base-shadow;
    cursor: move;
    display: none;
    z-index: 1;
  }
  td {
    border-color: #fff;
  }
  &:hover {
    .confirmable-action {
      display: block;
    }
    td {
      background: rgba(33, 150, 243, 0.08) !important;
    }
  }
  &.draggable:hover {
    span.drag-handle {
      display: block;
    }
  }
  &.dragging-over {
    td {
      border-color: $primary-color;
      border-style: solid;
      border-width: 12px;
      border-bottom: none;
      border-left: none;
      border-right: none;
      transition: 0.1s ease-in;
    }
  }
  td.action-cell {
    width: 50px;
    text-align: center;
  }
  td.highlight {
    background: #fafafa;
  }
}

@media (prefers-color-scheme: dark) {
    tr.item-editor {
        td.highlight {
            background: #424242;
        }
    }
}
