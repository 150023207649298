.estimate-placeholder {
  .estimate-header {
    padding: 40px 16px 32px;
  }
  .banner-placeholder-line {
    margin: 0;
  }
  .banner-placeholder-boxes {
    padding-top: 50px !important;
    .banner-placeholder-box {
      height: 100px;
      border-radius: 4px;
    }
  }
  .body-placeholder-boxes {
    padding-top: 50px !important;
    .body-placeholder-box {
      height: 150px;
      border-radius: 4px;
    }
  }
}
