span.confidence-dot {
  display: inline-block;
  width: 8px;
  position: relative;
  top: -2px;

  .dot {
    display: inline-block;
    width: 3px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    line-height: 14px;
    padding: 1px;
    height: 3px;
    margin-right: 1px;
    border-radius: 50%;
  }

  &.extremely-high .dot {
    background: $success-color;
    display: none;
  }

  &.high .dot {
    background: $primary-color;
    display: none;
  }

  &.medium .dot {
    background: $warning-color;
  }

  &.low .dot {
    background: $error-color;
  }
}
