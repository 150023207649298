div.status-editor {
  text-align: right;
  color: #fff;
  padding: 24px 40px 0;
  .status-select {
    background: #fff !important;
    box-shadow: $base-shadow;
  }
}

span.percentage-marker {
  position: absolute;
  top: 35%;
  right: 0;
}

@media print {
  .dashboard-actions,
  .estimate-detail-actions,
  .estimate-summary-actions {
    display: none;
  }
}

.estimate-info-editor {
  width: 500px;
}

.estimate-editor-actions {
  text-align: right;
  margin-bottom: 25px;
}

.estimate-breakdown-editor-actions {
  margin-top: 25px;
}

table.estimate-items {
  border-collapse: collapse;
  th {
    font-weight: normal;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  td {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  tr.name-missing {
    opacity: 0.25;
  }
  td.name-value-cell {
    padding-left: 10px;
  }
  td.task-type-cell {
    padding-left: 10px;
    width: 120px;
  }
  th.group-task-cell,
  td.group-task-cell {
    padding-left: 10px;
    width: 75px;
    line-height: 25px;
    text-align: center;
    span.team-member-count {
      position: absolute;
      color: #797979;
      right: 8px;
      top: 16px;
    }
  }
  th.currency-cell,
  td.currency-cell {
    width: 75px;
  }
  td.range-value-cell {
    width: 50px;
    text-align: center;
  }
  td.confidence-value-cell {
    width: 35px;
    text-align: right;
    vertical-align: middle;
  }
  td.description-value-cell {
    width: 35px;
    text-align: center;
    i {
      color: #ddd;
      transform: rotateY(180deg);
    }
    .has-description {
      span {
        color: $secondary-color;
      }
    }
  }
  .range-value {
    width: 32px;
    input {
      text-align: right;
    }
  }
}

table {
  tr.total-row {
    td {
      border-top: 1px solid #595959;
    }
  }
  th.action-cell,
  td.action-cell {
    width: 15px;
  }
  &.stripe > tbody > tr:nth-child(odd) {
    background-color: #fafafa;
  }
  &.collapse {
    border-collapse: collapse;
  }
  td {
    &.no-risk {
      background-color: lighten($success-color, 20%);
    }
    &.low-risk {
      background-color: lighten($warning-color, 20%);
    }
    &.medium-risk {
      background-color: lighten($error-color, 20%);
    }
    &.high-risk {
      background-color: lighten($error-color, 15%);
    }
    &.muted {
      &.no-risk {
        background-color: lighten($success-color, 30%);
      }
      &.low-risk {
        background-color: lighten($warning-color, 30%);
      }
      &.medium-risk {
        background-color: lighten($error-color, 30%);
      }
      &.high-risk {
        background-color: lighten($error-color, 15%);
      }
    }
    &.name-sm {
      width: 50px;
    }
  }
}

div.estimate-summary {
  padding: 0 40px;
  div.area {
    position: relative;
    margin-bottom: 50px;
    @media print {
      page-break-inside: avoid;
    }
    div.area-details {
      width: 100%;
    }
    header {
      font-size: 20px;
      span.area-name-dark {
        color: $primary-color-dark;
        position: relative;
        font-weight: 500;
      }
      span.area-name-light {
        color: $primary-color;
        position: relative;
        font-weight: 500;
      }
      p.area-description {
        color: #546e7a;
        font-size: 12px;
        font-weight: 200;
        padding-top: 0;
        margin: 0;
        padding-left: 0;
      }
    }
    div.area-hours,
    div.area-cost {
      font-size: 16px;
      text-align: right;
      font-weight: 300;
    }
    div.area-cost {
      font-weight: 400;
    }
    div.item {
      width: 100%;
      border-top: 1px dashed rgba(3, 66, 100, 0.1);
      &:last-child {
        border-bottom: 1px dashed rgba(3, 66, 100, 0.1);
      }
      &.items-missing {
        color: $error-color;
      }
      header {
        font-size: 14px;
        position: relative;
        span.confidence-dot {
          position: absolute;
          left: -5px;
          top: 0;
          opacity: 0.5;
        }
        span.item-name {
          font-size: 16px;
          display: block;
          font-weight: 500;
          padding-left: 8px;
        }
        p.item-description {
          font-size: 12px;
          font-weight: 200;
          padding-top: 0;
          margin: 0;
          padding-left: 8px;
        }
      }
      div.item-hours {
        font-size: 14px;
        font-weight: 500;
        flex: 1;
        text-align: end;
        align-self: center;
      }
      div.item-cost {
        font-size: 14px;
        font-weight: 600;
        text-align: right;
        flex: 1;
        align-self: center;
      }
      div.item-role {
        text-align: left;
        vertical-align: top;
        padding-left: 10px;
        .role-name {
          margin-top: 0;
        }
      }
    }
  }
  div.totals {
    div.subtotal,
    div.discount,
    div.total {
      header {
        font-size: 16px;
        font-weight: 500;
      }
      div.subtotal-cost,
      div.discount-cost,
      div.total-cost {
        border-top: 1px solid transparent;
        text-align: right;
        &.blended {
          font-style: italic;
          color: $primary-color;
        }
      }
      div.total-cost {
        border-top: 1px solid rgba(73, 155, 221, 0.2);
        font-size: 24px;
      }
    }
  }
  &.simple {
    div.area {
      header {
        font-size: 18px;
      }
      margin-bottom: 0;
    }
    div.totals {
      margin-top: 25px;
    }
  }
}

.flex-center {
  display: flex;
  align-content: center;
  align-items: flex-start;
}
.flex-end{
  display: flex;
  align-content: center;
  align-items: flex-end;
}
.align-self-vertically {
  flex: 1;
  align-self: center;
}

div.area-total {
  text-align: right;
  padding-right: 2px;
}

div.team {
  div.action-cell {
    text-align: right;
  }
  div.team-member {
    margin-bottom: 25px;
  }
  div.team-member-hint {
    color: $primary-color;
    font-size: 10px;
  }
}
div.team-member-durations {
  width: 500px;
  color: #595959;
  padding: 25px;
}

div.fetching-wrapper {
  padding: 25px 25px;
  border-radius: 2pt;
  width: 150px;
  height: 80px;
  text-align: center;
  vertical-align: middle;
  margin: 25px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

div.team-member-duration-card {
  margin-bottom: 5px;
  div.team-member-duration-card-name {
    margin-bottom: 2px;
    font-size: 14px;
  }
  div.team-member-duration-card-bar {
    background: $primary-color;
    height: 4px;
    margin-bottom: 2px;
  }
  div.team-member-duration-card-length {
    font-size: 10px;
  }
}

div.relative-duration-bar-wrapper {
  position: relative;
  background: #ddd;
}

div.relative-duration-bar {
  background: $primary-color;
  height: 2px;
  margin-top: 4px;
}

div.cost-cell {
  text-align: right;
  padding-right: 8px !important;
}

h3.estimate-team-form-header {
  i.material-icons {
    position: relative;
    top: 4px;
    font-size: 18px;
    margin-left: 5px;
  }
}

div.save-comment-field {
  width: 400px;
}

span.role-name {
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: $secondary-color;
  display: inline-block;
  margin-right: 5px;
  vertical-align: top;
  padding: 2px 5px;
  font-size: 12px;
  line-height: 18px;
}
span.team-member-summary-col {
  line-height: 24px;
  & > span {
    display: inline-block;
  }
}

span.no-role {
  background-color: #efefef;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #797979;
  display: inline-block;
  font-size: 0.9em;
  line-height: 1.4;
  margin-right: 5px;
  margin-top: 5px;
  vertical-align: top;
  padding: 2px 5px;
}

div.file-import {
  margin-top: 75px !important;
  padding: 16px;
  p {
    font-weight: 300;
  }
}

div.wide-editor {
  width: 400px;
}

.list-filters {
  &.in {
    margin-bottom: 24px;
    border-radius: 2px;
  }
}

div.preview-toggle {
  text-align: right;
  position: absolute;
  width: 100%;
  top: -12px;
}

.summary-item-tooltip {
  font-size: 12px;
  padding: 16px;
  .total-row {
    border-collapse: collapse;
    td {
      border-top: 1px solid rgba(255, 255, 255, 0.25);
      padding-top: 2px;
    }
  }
}

.exclude-switch {
  position: absolute !important;
  top: 64px;
  left: 0px;
}
.excluded-message {
  color: $error-color;
}

.speed-dial-action {
  background: #fff;
}

@media (prefers-color-scheme: dark) {
  table {
    &.stripe > tbody > tr:nth-child(odd) {
      background-color: #424242;
    }
  }
}
