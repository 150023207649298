.area-editor {
  .area-title {
    input {
      font-size: 18px !important;
      font-weight: 500;
    }
  }
  .area-summary {
    font-size: 16px;
    flex: 1;
    align-self: center;
    opacity: 0.5;
  }
}
.estimate-area {
  position: relative;
  padding: 0 0 24px !important;

  header {
    padding: 0 25px;
    .confirmable-action {
      position: absolute;
      right: 15px;
      top: 35px;
      button {
        border: 1px solid rgba(0, 0, 0, 0.025);
        background: #fff;
        i.material-icons {
          font-size: 18px;
        }
      }
    }
    .move-up-btn {
      position: absolute;
      right: 55px;
      top: 35px;
      border: 1px solid rgba(0, 0, 0, 0.025);
      background: #fff;
      i.material-icons {
        font-size: 18px;
      }
    }
  }
  .area-delete {
    position: absolute;
    right: 5px;
    top: 5px;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
  .area-title-wrapper {
    .form-control:first-child {
      flex: 1;
    }
    .confirmable-action {
      position: absolute;
      top: auto;
      left: auto;
      right: auto;
      bottom: 0;
      button: {
        border: none !important;
      }
      i {
        color: #ddd;
        transform: rotateY(180deg);
        font-size: 24px !important;
      }
      &.has-description {
        span {
          color: $secondary-color;
        }
      }
    }
    display: flex;
    font-size: 16px;
    width: 50%;
  }
}

div.area-editor {
  &.excluded {
    border: 1px dashed #ddd;
  }
}
