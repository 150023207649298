.estimate-status-badge {
  display: inline-block;
  margin: 8px 0;
  padding: 4px 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.025);

  &.status_DRAFT {
    color: #494949;
  }
  &.status_SUBMITTED_FOR_REVIEW {
    color: $primary-color;
    font-weight: 400;
  }
  &.status_RETURNED {
    color: $error-color;
    border-color: $error-color;
    background: rgba(198, 40, 40, 0.1);
    font-weight: 400;
  }
  &.status_APPROVED {
    color: $success-color;
  }
}

@media (prefers-color-scheme: dark) {
    .estimate-status-badge {
        &.status_SUBMITTED_FOR_REVIEW {
            color: $primary-color-dark;
        }
    }
}
