@import "@fontsource/montserrat/600.css";

.analytics-widget {
  header {
    margin-bottom: 15px;
  }
}
.bar-chart-widget g .bar,
.line-chart-widget g .bar {
  fill: $secondary-color;
  box-shadow: $base-shadow;
}

.bar-chart-widget g text.label,
.line-chart-widget g text.label {
  fill: #fff;
  font: 12px sans-serif;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  text-anchor: start;
  width: 150px;
  position: relative;
  z-index: 10;
}

g.abp .bar {
  fill: #9e9e9e;
}
g.hp .bar {
  fill: #9e9e9e;
}
g.final .bar {
  fill: $primary-color;
}

.bar-chart-widget g text.value,
.line-chart-widget g text.value {
  fill: #393939;
  font: 12px sans-serif;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
}

.bar-chart-widget g.is-zero-width text.value,
.line-chart-widget g.is-zero-width text.value {
  fill: #393939;
}

.bar-chart-widget g.has-action,
.line-chart-widget g.has-action {
  cursor: pointer;
}

.bar-chart-widget g .bar:hover,
.line-chart-widget g .bar:hover {
  opacity: 0.75;
}

.bar-chart-widget.top-posts,
.line-chart-widget.top-posts {
  height: 240px;
}

.dashboard-chart-widget g .bar {
  fill: #337ab7;
}

.dashboard-chart-widget g .bar:hover {
  opacity: 0.7;
}

.dashboard-chart-widget.yellow-chart g .bar {
  fill: #f5a623;
}

.dashboard-chart-widget.yellow-chart g .barSecond {
  fill: #3f51b5;
}

.dashboard-chart-widget.yellow-chart.bar-text {
  color: #f5a623;
}

.dashboard-chart-widget.yellow-chart.bar-textSecond {
  color: #3f51b5;
}

.dashboard-chart-widget.orange-chart g .bar {
  fill: #f5a623;
}

.dashboard-chart-widget.blue-chart g .bar {
  fill: #f5a623;
}

.users-not-enough-data {
  text-align: center;
}

.tags-chart-widget g {
  cursor: pointer;
}

.tags-chart-widget g .bar {
  fill: #9c27b0;
}

.tags-chart-widget g .bar:hover {
  opacity: 0.7;
}

.reactions-chart-widget g .bar {
  fill: #3f51b5;
}

.reactions-chart-widget g .bar:hover {
  opacity: 0.7;
}

.line {
  fill: none;
  stroke: #0056b3;
  stroke-width: 2px;
}

.lineSecond {
  fill: none;
  stroke: #00897b;
  stroke-width: 2px;
}

.area {
  fill: #337ab7;
  stroke-width: 1px;
}

.areaSecond {
  fill: #d8f3f0;
}

.hover-line {
  stroke-width: 2px;
  stroke-dasharray: 4px;
}

.hover-box {
  fill: rgba(216, 243, 240, 0.1);
}

.hover-text-active-users {
  fill: #0056b3;
  font-weight: bold;
  border-bottom: 4px;
}

.hover-text-registered-users {
  font-weight: bold;
  fill: #00897b;
}

.hover-text-date {
  font-weight: bold;
  fill: rgba(0, 0, 0, 0.8);
}

.reads-chart-widget g .bar {
  fill: #337ab7;
}

.reads-chart-widget g .bar:hover {
  opacity: 0.7;
}

.app-usage g path:hover {
  opacity: 0.7;
}

.x-axis g.tick text {
  transform: translate(15px, 10px) rotate(45deg);
}

.user-trend {
  padding-top: 15px;
  position: relative;
  min-height: 160px;
  height: 160px;
}

.user-trend .last-month {
  width: 140px;
  padding-left: 10px;
  position: absolute;
}

.user-trend .user-trend-description {
  padding-top: 10px;
  margin-left: 185px;
  font-size: 14px;
  text-align: left;
}

.user-trend .user-trend-description p {
  margin-bottom: 0;
}

.user-trend .user-trend-description .user-trend-description-icon-wrapper {
  text-align: center;
}

.user-trend .user-trend-description .user-trend-description-icon-wrapper .material-icons {
  font-size: 64px;
  color: #ddd;
}

.user-trend .user-trend-circle {
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  border-radius: 50%;
  border: 20px solid #00bcd4;
  font-size: 54px;
}

.user-trend .user-trend-circle.trending-up {
  border-color: #009688;
}

.user-trend .user-trend-circle.trending-down {
  border-color: #f44336;
}

.user-trend .user-trend-circle-footer {
  padding: 5px 0;
  text-align: center;
  font-size: 14px;
}

.app-usage {
  position: relative;
  min-height: 160px;
}

.app-usage .chart-wrapper {
  position: absolute;
}

.app-usage .legend {
  margin-left: 165px;
  padding-top: 15px;
}

.app-usage .legend header {
  font-size: 14px !important;
  font-weight: normal !important;
  padding: 0 !important;
  margin-bottom: 0;
}

.app-usage .legend ul {
  list-style: none;
  padding-left: 5px !important;
  margin: 0;
}

.app-usage .legend ul li {
  line-height: 22px;
  font-size: 14px;
}

.app-usage .legend .color-indicator {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 5px;
  position: relative;
  top: 2px;
}
.app-usage .legend .value {
  color: #393939;
  font-weight: 300;
}

.post-awareness {
  position: relative;
  padding: 0;
}

.post-awareness .awareness-graph-wrapper {
  padding-left: 15px;
}

.post-awareness .awareness-graph-wrapper .line {
  fill: none;
  stroke: #2196f3;
  stroke-width: 2px;
}

.post-awareness .awareness-graph-wrapper .area {
  fill: rgba(33, 150, 243, 0.15);
}

.post-awareness .awareness-badge {
  position: absolute;
  right: 15px;
  top: 50px;
}

.post-awareness .awareness-badge .awareness-circle {
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  border-radius: 50%;
  border: 20px solid #ff9800;
  font-size: 54px;
}

.post-awareness .awareness-badge .awareness-circle.high {
  border-color: #8bc34a;
}

.post-awareness .awareness-badge .awareness-circle.low {
  border-color: #f44336;
}

.post-awareness .awareness-badge .awareness-circle-footer {
  padding: 5px 0;
  text-align: center;
  font-size: 14px;
}

.post-content-reset-styles {
  background: rgba(243, 243, 244, 0.24);
  border: 1px solid #ddd;
  padding: 15px;
}

.post-content-reset-styles h1 {
  font-size: 22px;
}

.post-content-reset-styles h2 {
  font-size: 20px;
}

.post-content-reset-styles h3,
.post-content-reset-styles h4,
.post-content-reset-styles h5,
.post-content-reset-styles h6 {
  font-size: 18px;
}

.bar-group .top-label {
  opacity: 0;
  transition: 1s;
  transition-delay: 1s;
}

.bar-group:hover .top-label {
  opacity: 1;
  transition: 0.3s;
  transition-delay: 0.1s;
}

.comment {
  margin-top: 8px !important;
  color: #0c0c0c;
    .comment-abp-tag{
        color:$primary-color;
    }
}
@media (prefers-color-scheme: dark) {
    .comment {
        color: #fff;
        .comment-abp-tag {
            color: #6CD9CA;
        }
    }

    .bar-chart-widget g.abp text.label,
    .line-chart-widget g.abp text.label {
        fill: #0c0c0c;
    }

    .bar-chart-widget g.hp text.label,
    .line-chart-widget g.hp text.label {
        fill: #0c0c0c;
    }

    .bar-chart-widget g text.value,
    .line-chart-widget g text.value {
        fill: #fff;
    }

    .bar-chart-widget g.is-zero-width text.value,
    .line-chart-widget g.is-zero-width text.value {
        fill: #fff;
    }
    .app-usage .legend .value {
        color: #fff;
        font-weight: 300;
    }
}
