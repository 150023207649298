$base-background-color: #e9e8e9;
$base-font: "Roboto", sans-serif;
$base-font-size: 16px;
$base-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
$base-shadow-inset: 0px 2px 4px -1px rgba(0, 0, 0, 0.2) inset, 0px 4px 5px 0px rgba(0, 0, 0, 0.14) inset,
  0px 1px 10px 0px rgba(0, 0, 0, 0.12) inset;

$item-border: 1px solid #efefef;

$header-background-color: #fff;
$header-font-color: #393939;

$base-text-color: #002131;

$primary-color: #00675B;
$primary-color-dark: #6CD9CA;
$secondary-color: #7cb448;

$success-color: #388e3c;
$warning-color: #ef6c00;
$error-color: #c62828;

$subdued: #696969;


