@import "variables";

html,
body {
  padding: 0;
  margin: 0;
  font-size: $base-font-size;
  @media print {
    background: none;
  }
}

h1 {
  font-size: 32px;
}
h1,
h2,
h3 {
  font-weight: normal;
}

pre {
  font-family: $base-font;
  font-size: $base-font-size;
  white-space: pre-wrap;
  word-wrap: break-word;
}

span.toggle-label {
  position: relative;
  top: -5px;
}
.floating-action-button {
  position: fixed !important;
  right: 50px;
  bottom: 50px;
  z-index: 100;
  text-decoration: none;

  @media print {
    display: none;
  }
}

span.negative-number {
  color: $error-color;
}

table {
  &.extra-padding {
    th,
    td {
      padding: 2px 20px;
    }
    th:first-child,
    td:first-child {
      padding-left: 0;
    }
  }
  &.full-width {
    width: 100%;
  }

  th.left,
  td.left {
    text-align: left;
    input {
      text-align: left;
    }
  }
  th.center,
  td.center {
    text-align: center;
    input {
      text-align: center;
    }
  }
  th.right,
  td.right {
    text-align: right;
    input {
      text-align: right;
    }
  }
  th.number-md,
  td.number-md {
    width: 50px;
  }
  td.number-md {
    padding-left: 10px;
  }
  th.number-sm,
  td.number-sm {
    width: 32px;
  }
  th,
  td {
    position: relative;
  }
  td.tag-cell {
    min-width: 175px;
  }
}

@import "../misc/styles/index";
@import "../charts/styles/index";
@import "../estimates/editor/styles/index";
@import "../estimates/detail/styles/index";

@import "components/estimates-refactor-me";
