.placeholder-line {
  opacity: 0.8;
  width: 100%;
  height: 15px;
  margin-bottom: 5px;
  display: block;
  margin-bottom: 10px;
  animation: fadeIn 1s infinite alternate;
  &.thin {
    height: 8px;
  }
  &.thick {
    height: 15px;
  }
  &.light {
    background: #efefef;
  }
  &.extralight {
    background: #fff;
  }
  &.dark {
    background: #ddd;
  }
}
