div.created-by {
  position: relative;
  padding-top: 5px;
  span.author {
    margin-left: 40px;
    line-height: 16px;
    color: #595959;
    display: block;
  }
  span.create-date,
  span.revised-date {
    margin-left: 40px;
    line-height: 18px;
    font-size: 12px;
    color: #797979;
    display: block;
  }
  pre {
    background: #fdfdfd;
    padding: 10px;
    margin-left: 40px;
    margin-top: 5px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    color: #595959;
  }
}
