.loading {
  position: relative;
  display: block;
  padding: 0;
  width: 100px;
  height: 15px;
  text-align: center;

  i {
    position: relative;
    display: inline-block;
    margin: 0 4px;
    width: 5px;
    height: 15px;
    border-radius: 2px;
    border: solid $secondary-color;
    border-width: 0 1px 1px 0;
    background: $secondary-color;
    animation: tilt infinite 5s cubic-bezier(0.955, -0.01, 1, 1);
    transform-origin: 100% 100%;
  }

  i::before {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: 0;
    display: block;
    width: 5px;
    height: 5px;
    background: transparent;
    box-shadow: 0 2px rgba(0, 0, 0, 0.3);
    animation: stretch infinite 5s cubic-bezier(0.955, -0.01, 1, 1);
    opacity: 0;
    transform-origin: 0% 0%;
  }

  i:nth-child(2),
  i:nth-child(2)::before {
    animation-delay: 0.3s;
  }

  i:nth-child(3),
  i:nth-child(3)::before {
    animation-delay: 0.6s;
  }

  i:nth-child(4),
  i:nth-child(4)::before {
    animation-delay: 0.9s;
  }

  i:nth-child(5),
  i:nth-child(5)::before {
    animation-delay: 1.2s;
  }

  i:last-of-type {
    animation-name: tilt-last;
  }

  i:last-of-type::before {
    animation-name: stretch-last;
  }

  footer {
    padding-left: 10px;
    color: #797979;
  }
}

@keyframes tilt {
  10% {
    transform: rotate(70deg);
  }
  60% {
    transform: rotate(70deg);
  }
  70% {
    transform: rotate(0deg);
  }
}

@keyframes tilt-last {
  10% {
    transform: rotate(90deg);
  }
  60% {
    transform: rotate(90deg);
  }
  70% {
    transform: rotate(0deg);
  }
}

@keyframes stretch {
  10% {
    transform: rotate(-70deg);
    width: 10px;
    opacity: 1;
    left: 0px;
    bottom: -1px;
  }
  60% {
    transform: rotate(-70deg);
    width: 10px;
    opacity: 1;
  }
  70% {
    width: 5px;
    transform: rotate(0deg);
    opacity: 0;
  }
}

@keyframes stretch-last {
  10% {
    transform: rotate(-90deg);
    width: 10px;
    height: 2px;
    opacity: 0.5;
    left: 3px;
    bottom: -1px;
  }
  60% {
    transform: rotate(-90deg);
    width: 10px;
    height: 2px;
    opacity: 0.5;
  }
  70% {
    transform: rotate(0deg);
    width: 5px;
    height: 5px;
    opacity: 0;
  }
}
